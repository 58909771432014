import React, { Suspense, lazy, useMemo, useState } from 'react';
import type { FC } from 'react';
import currency from 'currency.js';
import { isMobileOnly } from 'react-device-detect';
import { Button, Descriptions, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import type { ILoan } from 'models/loan';
import { LoanStatus, LoanTermsInterestAccruesDailyFirstPaymentBasis, LoanTermsInterestSchedule, LoanType } from 'models/loan';
import { ILoanSchedule } from 'models/loanSchedule';

import { LateFeeTierTag } from 'components/lateFees';
import { LongCurrency, SimpleDate } from 'utils/formatting';
import { SimpleTooltip } from 'utils/tooltipWrapper';

const LoanDownPaymentCollectionModal = lazy(() => import('components/loans/downPaymentCollectionModal'));

interface ILoanTabDetailsProps {
    loan: ILoan;
    schedule?: ILoanSchedule;
}

export const LoanTabDetails: FC<ILoanTabDetailsProps> = React.memo(({ loan, schedule }) => {
    const [downPaymentModalOpen, setDownPaymentModalOpen] = useState(false);

    const totalDueTooltip = useMemo(() => <Tooltip overlay="Total due is a sum of the late fees and other fees due plus the balance due (does NOT include property tax).">Total Due <InfoCircleOutlined /></Tooltip>, []);
    const paymentAmountTooltip = useMemo(() => {
        if (!loan.escrow || !loan.escrow.paymentAmount) {
            return (
                <React.Fragment>Payment Amount</React.Fragment>
            );
        }

        return (
            <Tooltip overlay="Includes the escrow payment.">Payment Amount <InfoCircleOutlined /></Tooltip>
        );
    }, [loan]);

    const onlinePaymentItems = useMemo(() => {
        if (!loan || !loan.onlinePaymentConfig) {
            return null;
        }

        if (!loan.onlinePaymentConfig.enabled) {
            return (
                <Descriptions.Item label="Online Payments">Disabled</Descriptions.Item>
            );
        }

        return (
            <React.Fragment>
                <Descriptions.Item label="Online Payments">
                    <Tooltip overlay={`Statement Descriptor: ${loan.onlinePaymentConfig.statementDescriptor}`}>
                        Enabled
                    </Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label="Platform Fee Payee" className="title-caps">
                    {loan.onlinePaymentConfig.platformFeePayee.replaceAll('-', ' ')}
                </Descriptions.Item>
            </React.Fragment>
        )
    }, [loan]);

    const autoDraftItems = useMemo(() => {
        if (!loan || !loan.onlinePaymentConfig || !loan.onlinePaymentConfig.autoDraft) {
            return null;
        }

        if (!loan.onlinePaymentConfig.allowAutoDraft) {
            return (
                <Descriptions.Item label="Auto Draft">Not Allowed</Descriptions.Item>
            );
        }

        const autoDraft = loan.onlinePaymentConfig.autoDraft;

        if (!autoDraft.enabled) {
            return null;
        }

        return (
            <React.Fragment>
                <Descriptions.Item label="Auto Draft">
                    <Tooltip overlay={`Payment amount: $${autoDraft.paymentAmount}`}>
                        Enabled
                    </Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label="Next Auto Draft Date"><SimpleDate date={autoDraft.nextDate} /></Descriptions.Item>
            </React.Fragment>
        );
    }, [loan]);

    const interestAccruesDaily = useMemo(() => {
        if (loan.terms.interestSchedule !== LoanTermsInterestSchedule.AccruesDaily) {
            return null;
        }

        return (
            <React.Fragment>
                <Descriptions.Item label="Accrued Interest"><LongCurrency value={loan.balance.accruedInterest} /></Descriptions.Item>
                <Descriptions.Item label="Unpaid Interest"><LongCurrency value={loan.balance.interest} /></Descriptions.Item>
            </React.Fragment>
        );
    }, [loan]);

    const lateFeeConfig = useMemo(() => {
        return (
            <React.Fragment>
                <Descriptions.Item label="Late Fees Applied">{loan.lateFeeConfig?.disabled ? 'Manually' : 'Automatically'}</Descriptions.Item>
                {loan.lateFeeConfig?.tiers.map((t, i) => (
                    <React.Fragment key={`late-fee-tier-${i}`}>
                        <Descriptions.Item label={`Late Fee Tier #${i + 1}`}><LateFeeTierTag value={t} /></Descriptions.Item>
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    }, [loan]);

    const escrow = useMemo(() => {
        if (typeof loan.escrow === 'undefined') {
            return null;
        }

        return (
            <React.Fragment>
                <Descriptions.Item label="Escrow Balance"><LongCurrency value={loan.escrow.balance} /></Descriptions.Item>
                <Descriptions.Item label="Escrow Payment Amount"><LongCurrency value={loan.escrow.paymentAmount} /></Descriptions.Item>
                <Descriptions.Item label="Escrow Step" className="title-caps">{loan.escrow.applicationStep.replace('-', ' ')}</Descriptions.Item>
                <Descriptions.Item label="Last Escrow Entry"><SimpleDate date={loan.escrow.lastEntryDate} /></Descriptions.Item>
            </React.Fragment>
        );
    }, [loan]);

    const defaulting = useMemo(() => {
        return (
            <React.Fragment>
                <Descriptions.Item label="Days Until In-Default">{loan.defaulting?.days || '-'}</Descriptions.Item>
                <Descriptions.Item label={
                    <SimpleTooltip
                        title={<React.Fragment>For more information, <a href={`${process.env.REACT_APP_DOCS_URL}/app/guides/creating-a-loan#step2-days-until-default`} target="_blank" rel="noopener noreferrer">click here</a>.</React.Fragment>}
                        content="Automatic Defaulting Enabled"
                    />
                }
                >{loan.defaulting?.automatic ? 'Yes' : 'No'}</Descriptions.Item>
                {loan.defaulting?.automatic ? <Descriptions.Item label="Defaults After">{loan.defaulting?.defaultsAfter} Days</Descriptions.Item> : null}
            </React.Fragment>
        );
    }, [loan]);

    const downPayment = useMemo(() => {
        if (!loan || !loan.downPayment) {
            return null;
        }

        return (
            <React.Fragment>
                <Descriptions.Item label="Online Down Payment"><Button size="small" onClick={() => setDownPaymentModalOpen(true)}>View Details</Button></Descriptions.Item>
            </React.Fragment>
        );
    }, [loan]);

    return (
        <React.Fragment>
            <Descriptions size="small" column={isMobileOnly ? 2 : 3} layout={isMobileOnly ? 'vertical' : 'horizontal'}>
                <Descriptions.Item label="Sales Price"><LongCurrency value={loan.terms.salesPrice} /></Descriptions.Item>
                <Descriptions.Item label="Down Payment"><LongCurrency value={loan.terms.downPayment} /></Descriptions.Item>
                {loan.type === LoanType.Residential ? <Descriptions.Item label="Total Adjustments"><LongCurrency value={loan.terms.adjustments} /></Descriptions.Item> : null}
                <Descriptions.Item label="Loan Amount"><LongCurrency value={loan.terms.amount!} /></Descriptions.Item>
                <Descriptions.Item label="Payment Frequency">Monthly</Descriptions.Item>
                <Descriptions.Item label="Payment Terms">{currency(loan.terms.length, { precision: 0 }).format()} {loan.terms.lengthUnit}</Descriptions.Item>
                <Descriptions.Item label="Interest Rate">{currency(loan.terms.rate).format()}%</Descriptions.Item>
                <Descriptions.Item label="Interest Schedule" className="title-caps">{loan.terms.interestSchedule.replaceAll('-', ' ')}</Descriptions.Item>
                {loan.terms.interestSchedule === LoanTermsInterestSchedule.AccruesDaily ? <Descriptions.Item label="Interest Formula" className="title-caps">{loan.terms.interestFormula ? loan.terms.interestFormula.replaceAll('/', ' / ') : '-'}</Descriptions.Item> : null}
                {loan.terms.interestSchedule === LoanTermsInterestSchedule.AccruesDaily ? <Descriptions.Item label="Finance Start" className="title-caps">{loan.terms.firstPaymentBasis ? loan.terms.firstPaymentBasis.replaceAll('-', ' ') : '-'}</Descriptions.Item> : null}
                {loan.terms.interestSchedule === LoanTermsInterestSchedule.AccruesDaily && loan.terms.firstPaymentBasis === LoanTermsInterestAccruesDailyFirstPaymentBasis.DownPaymentDate ? <Descriptions.Item label="Down Payment Date"><SimpleDate date={loan.downPaymentDate} /></Descriptions.Item> : null}
                {loan.hasSchedule ? <Descriptions.Item label="Total of Payments"><LongCurrency value={schedule?.totalPayments} tooltip /></Descriptions.Item> : null}
                {loan.hasSchedule ? <Descriptions.Item label="Total Interest"><LongCurrency value={schedule?.totalInterest} tooltip /></Descriptions.Item> : null}
                <Descriptions.Item label={paymentAmountTooltip}><LongCurrency value={loan.terms.payment!} /></Descriptions.Item>
                <Descriptions.Item label="Next Due Date"><SimpleDate date={loan.nextDueDate} /></Descriptions.Item>
                <Descriptions.Item label="Last Payment Date"><SimpleDate date={loan.lastPaymentReceivedDate} /></Descriptions.Item>
                <Descriptions.Item label="Previous Due Date"><SimpleDate date={loan.previousDueDate} /></Descriptions.Item>
                <Descriptions.Item label="Remaining Principal"><LongCurrency value={loan.balance.principal} tooltip /></Descriptions.Item>
                <Descriptions.Item label="Balance Due"><LongCurrency value={loan.balance.due} /></Descriptions.Item>
                <Descriptions.Item label="Late Fees Due"><LongCurrency value={loan.balance.lateFees} /></Descriptions.Item>
                <Descriptions.Item label="Other Fees Due"><LongCurrency value={loan.balance.otherFees} /></Descriptions.Item>
                {interestAccruesDaily}
                <Descriptions.Item label={totalDueTooltip}><LongCurrency value={loan.balance.totalDue || '0'} /></Descriptions.Item>
                {loan.balance.propertyTax && loan.balance.propertyTax !== '0' ? <Descriptions.Item label="Property Tax Due">{currency(loan.balance.propertyTax).format(true)}</Descriptions.Item> : null}
                {defaulting}
                <Descriptions.Item label="First Due Date"><SimpleDate date={loan.firstPaymentDate} /></Descriptions.Item>
                <Descriptions.Item label="Closing Date"><SimpleDate date={loan.closingDate} /></Descriptions.Item>
                <Descriptions.Item label="Communication" className="title-caps">{loan.communication.preferences.join(', ')} ({loan.communication.automated ? 'automatic' : 'manual'})</Descriptions.Item>
                <Descriptions.Item label="Extra Application" className="title-caps">{loan.terms.extraApplication ? loan.terms.extraApplication.replaceAll('-', ' ') : '-'}</Descriptions.Item>
                {loan.terms.wasExisting ? <Descriptions.Item label="Pre-existing Loan">Yes</Descriptions.Item> : null}
                {onlinePaymentItems}
                {autoDraftItems}
                {loan.status === LoanStatus.Repossessed ? <Descriptions.Item label="Repossessed Date"><SimpleDate date={loan.repossessedDate} /></Descriptions.Item> : null}
                {escrow}
                {lateFeeConfig}
                {downPayment}
            </Descriptions>

            <Suspense fallback={null}>
                <LoanDownPaymentCollectionModal
                    open={downPaymentModalOpen}
                    readOnly
                    downPaymentAmount={currency(loan?.terms?.downPayment || '0', { precision: 2 })}
                    initial={loan?.downPayment}
                    close={() => setDownPaymentModalOpen(false)}
                />
            </Suspense>
        </React.Fragment>
    );
});
