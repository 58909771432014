import React, { lazy, Suspense } from 'react';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { PageContainer } from '@ant-design/pro-layout';
import type { PageContainerProps } from '@ant-design/pro-layout';
import { DownOutlined, EyeOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons';
import { Row, Col, Card, Button, Result, Tooltip, Descriptions, message, Dropdown, Typography, Alert } from 'antd';
import type { BreadcrumbProps } from 'antd/lib/breadcrumb';
import { isMobileOnly } from 'react-device-detect';

import { PermissionAction, PermissionFeature } from 'models/permissions/features';
import { hasAccessToFeature } from 'store/selectors/permissions';
import { AccessControlled, AccessControlledButton } from 'components/permissions';

import { breadCrumbItemRender } from 'utils/breadCrumbs';
import { SimpleDate } from 'utils/formatting';

import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';
import { ILoadingState } from 'models/props-or-state/states';

import { AddEditEntityDrawer } from 'components/entity/addEditDrawer';
import { ClientStatusTag } from 'components/clients/clientStatusTag';
import { EntityTable } from 'components/entity/table';
import { TimelineView } from 'components/timeline';
import { FilesCard } from 'components/files';
import { SendMailModal } from 'components/mail/sendMail';
import { PaymentMethodCard } from 'components/billing/paymentMethodCard';

import { ClientCommunicationAndNotes } from './communicationAndNotes';
import { ClientTractsAndLoans } from './activeItems';
import { ClientLendiomPayButtons } from './payButtons';
import { ClientActionButtons } from './clientActionButtons';

import { ClientBillingAllowedPaymentMethods, ClientType } from 'models/client';
import { IEntity } from 'models/entity';
import { INote } from 'models/notes';
import { ITimelineEntry } from 'models/timelineEntry';
import { RelatedToType } from 'models/common/relatedTo';
import { LanguageDisplay } from 'models/common/language';
import { IMail } from 'models/mail';

import { GlobalState } from 'store';
import { getSelectedOrg, isSelectedOrgAllowedToCreate } from 'store/selectors/org';
import { getClientById, hasLoadingError, loadingError } from 'store/selectors/clients';
import { fetchClientById } from 'store/actions/clients';

import { getClientEntity, getClientEntities, getClientNotes, getClientTimelineEntries, getClientMails } from 'api/clients';

//TODO: convert this less file into style={{}}
import './index.less';

const ViewPendingChangeModal = lazy(() => import('./pendingChangeModal'));

interface PathParamsType extends IOrgIdPathParams {
    clientId: string;
}

const mapStateToProps = (state: GlobalState, props: RouteComponentProps<PathParamsType>) => ({
    selectedOrg: getSelectedOrg(state),
    canCreate: isSelectedOrgAllowedToCreate(state),
    client: getClientById(state, props.match.params.orgId, props.match.params.clientId),
    hasLoadingError: hasLoadingError(state),
    loadingError: loadingError(state),
    hasAccessTo: hasAccessToFeature(PermissionFeature.Client)(state),
});

interface IClientProps extends ReturnType<typeof mapStateToProps>, DispatchProp, RouteComponentProps<PathParamsType> { }

interface IClientState extends ILoadingState {
    loadedTime: Dayjs;
    primaryEntity?: IEntity;
    entities: IEntity[];
    notes: INote[];
    timelineEntries: ITimelineEntry[];
    mails: IMail[];
    isAddEntityVisible: boolean;
    isSendMailVisible: boolean;
    isPendingUpdateVisible: boolean;
}

class ClientBase extends React.PureComponent<IClientProps, IClientState> {
    state: Readonly<IClientState> = {
        loadedTime: dayjs(),
        isLoading: true,
        entities: [],
        notes: [],
        timelineEntries: [],
        mails: [],

        isAddEntityVisible: false,
        isSendMailVisible: false,
        isPendingUpdateVisible: false,
    };

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate(prevProps: IClientProps) {
        const client = this.props.client;

        if (this.props.match.params.orgId === prevProps.match.params.orgId) {
            if (!client) {
                return;
            }

            if (dayjs(client.modifiedAt).isAfter(this.state.loadedTime)) {
                this.refreshData();
            }

            return;
        }

        this.refreshData();
    }

    refreshData = async () => {
        const { orgId } = this.props.match.params;

        try {
            if (!this.props.client) {
                await this.props.dispatch(fetchClientById(this.props.match.params.clientId) as any);
                if (!this.props.client) {
                    return;
                }
            }

            const client = this.props.client;

            const [primaryEntity, entities, notes, timelineEntries, mails] = await Promise.all([
                getClientEntity(orgId, client.id, client.primaryEntity.id),
                getClientEntities(orgId, client.id),
                getClientNotes(orgId, client.id),
                getClientTimelineEntries(orgId, client.id),
                getClientMails(orgId, client.id),
            ]);

            //TODO: if they can't read the notes, timeline entries, etc then don't fetch them

            this.setState({
                loadedTime: dayjs(),
                isLoading: false,
                primaryEntity,
                entities,
                notes,
                timelineEntries,
                mails,
            });
        } catch (e) {
            console.warn('Error while loading the client:', e);
        }
    }

    isAnIndividual = (): boolean => {
        return typeof this.props.client === 'undefined' || this.props.client.type === ClientType.Individual;
    }

    toggleEntityClick = async () => {
        this.setState((prevState: Readonly<IClientState>) => {
            return {
                ...prevState,
                isAddEntityVisible: !prevState.isAddEntityVisible,
            };
        });
    }

    get addEntityButton() {
        if (this.isAnIndividual()) {
            return null;
        }

        return (
            <AccessControlledButton
                type="dashed"
                size="small"
                onClick={this.toggleEntityClick}
                icon={<PlusOutlined />}
                feature={PermissionFeature.ClientEntities}
                action={PermissionAction.Create}
                prevent="tooltip"
            >Add Entity</AccessControlledButton>
        );
    }

    //#region pending update
    get hasPendingUpdate() {
        if (!this.props.client || !this.props.client.hasPendingUpdate) {
            return null;
        }

        return (
            <Alert
                showIcon
                type="info"
                message="Pending Update"
                description="This client has provided updated information, the update request is pending."
                action={
                    <AccessControlledButton
                        type="primary"
                        size="large"
                        onClick={this.togglePendingUpdateClick}
                        icon={<EyeOutlined />}
                        feature={PermissionFeature.ClientEntities}
                        action={PermissionAction.Update}
                        prevent="tooltip"
                    >View Request</AccessControlledButton>
                }
            />
        );
    }

    togglePendingUpdateClick = async () => {
        this.setState((prevState: Readonly<IClientState>) => {
            return {
                ...prevState,
                isPendingUpdateVisible: !prevState.isPendingUpdateVisible,
            };
        });
    }

    get pendingUpdateModal() {
        return (
            <Suspense fallback={null}>
                <ViewPendingChangeModal
                    open={this.state.isPendingUpdateVisible}
                    client={this.props.client}
                    onClose={this.togglePendingUpdateClick}
                />
            </Suspense>
        );
    }
    //#endregion pending update

    get entitiesAndRelated() {
        if (!this.props.client) {
            return null;
        }

        return (
            <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
                <Col xs={24} lg={14}>
                    <Card
                        title={this.isAnIndividual() ? 'Entity' : 'Entities'}
                        bordered={false}
                        extra={this.addEntityButton}
                    >
                        <EntityTable entities={this.state.entities} client={this.props.client!} pageSize={3} refresh={this.refreshData} />
                        <AddEditEntityDrawer
                            isVisible={this.state.isAddEntityVisible}
                            orgId={this.props.client.organization.id}
                            clientId={this.props.client.id}
                            clientType={this.props.client.type}
                            close={this.toggleEntityClick}
                            save
                        />
                    </Card>
                </Col>
                <Col xs={24} lg={10}>
                    <ClientTractsAndLoans orgId={this.props.match.params.orgId} client={this.props.client} />

                    <PaymentMethodCard for={this.props.client} style={{ marginTop: '24px' }} />
                </Col>
            </Row>
        );
    }

    get filesAndCommunication() {
        if (!this.props.client) {
            return null;
        }

        return (
            <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
                <Col xs={24} lg={12}>
                    <ClientCommunicationAndNotes
                        orgId={this.props.match.params.orgId}
                        client={this.props.client}
                        notes={this.state.notes}
                        mails={this.state.mails}
                        refresh={this.refreshData}
                        onNewMailClick={this.onMailClick}
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <FilesCard
                        orgId={this.props.match.params.orgId}
                        relatedTo={{
                            type: RelatedToType.CLIENT,
                            id: this.props.client.id,
                        }}
                        relatedLabel={this.props.client.displayName}
                    />
                </Col>
            </Row>
        );
    }

    get timelineCard() {
        return (
            <AccessControlled feature={PermissionFeature.ClientTimeline} action={PermissionAction.Read}>
                <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
                    <Col span={24}>
                        <TimelineView view="card" related={this.props.client!} relatedType={RelatedToType.CLIENT} entries={this.state.timelineEntries} refresh={this.refreshData} />
                    </Col>
                </Row>
            </AccessControlled>
        );
    }

    //#region mail button
    onMailClick = async () => {
        if (!this.props.selectedOrg || !this.props.client) {
            return;
        }

        this.setState({ isSendMailVisible: true });
    }

    onMailClose = async (sent: boolean) => {
        if (sent) {
            await this.refreshData();
        }

        this.setState({ isSendMailVisible: false });
    }

    get mailButton() {
        if (!this.props.client || !this.state.entities || !this.props.hasAccessTo.update || !this.props.canCreate) {
            return null;
        }

        const noAddresses = typeof this.state.entities.find((e) => Array.isArray(e.addresses) && e.addresses.length >= 1) === 'undefined';

        let button: React.ReactNode = <Button icon={<PrinterOutlined />} onClick={this.onMailClick} disabled={noAddresses}>Mail</Button>;
        if (noAddresses) {
            button = (
                <Tooltip overlay="Client has no addresses associated" placement="bottomRight">
                    {button}
                </Tooltip>
            );
        }

        return button;
    }

    get mailModal() {
        return (
            <SendMailModal
                isVisible={this.state.isSendMailVisible}
                client={this.props.client}
                close={this.onMailClose}
                entities={this.state.entities}
            />
        );
    }
    //#endregion mail button

    get payButton() {
        if (!this.props.client || !this.state.entities) {
            return null;
        }

        return (
            <Dropdown dropdownRender={() => <ClientLendiomPayButtons org={this.props.selectedOrg} client={this.props.client} entities={this.state.entities} />}>
                <Button>
                    Lendiom Pay <DownOutlined />
                </Button>
            </Dropdown>
        );
    }

    //#region header
    get headerTags(): React.ReactElement | undefined {
        if (!this.props.client) {
            return undefined;
        }

        return <ClientStatusTag key="client-status-tag" status={this.props.client.status} />;
    }

    get headerExtra() {
        return (
            <Button.Group>
                {this.payButton}
                {this.mailButton}

                <ClientActionButtons orgId={this.props.match.params.orgId} client={this.props.client} entities={this.state.entities} refreshData={this.refreshData} />
            </Button.Group>
        );
    }

    onAccountNumberCopy = () => {
        message.info('Account Number copied to clipboard!');
    }

    get allowedPaymentMethods() {
        if (!this.props.client || !this.props.client.billing || !this.props.client.billing.allowedPaymentMethods) {
            return '-';
        }

        if (this.props.client.billing.allowedPaymentMethods.length === 0) {
            return 'None';
        }

        return this.props.client.billing.allowedPaymentMethods.map((v) => v === ClientBillingAllowedPaymentMethods.BankAccounts ? 'Bank Accounts' : 'Credit/Debit Cards').join(' & ');
    }

    get clientHeaderInfo() {
        if (!this.props.client) {
            return null;
        }

        let language = LanguageDisplay[this.props.client.preferences?.language!] || '-';

        return (
            <Descriptions size="small" column={isMobileOnly ? 2 : 3} layout={isMobileOnly ? 'vertical' : 'horizontal'}>
                <Descriptions.Item label="Account Number">
                    <Typography.Paragraph style={{ marginBottom: 'unset', fontFamily: 'monospace' }} copyable={{ onCopy: this.onAccountNumberCopy }}>{this.props.client.accountNumber}</Typography.Paragraph>
                </Descriptions.Item>
                <Descriptions.Item label="Portal Last Activity"><SimpleDate date={this.props.client.portal.lastActivity} /></Descriptions.Item>
                <Descriptions.Item label="Language">{language}</Descriptions.Item>
                <Descriptions.Item label="Allowed Payment Methods" span={3}>{this.allowedPaymentMethods}</Descriptions.Item>
            </Descriptions>
        );
    }

    get breadcrumbProps(): BreadcrumbProps {
        return {
            itemRender: breadCrumbItemRender,
            items: [
                {
                    path: `/${this.props.match.params.orgId}`,
                    breadcrumbName: 'Dashboard',
                },
                {
                    path: `/${this.props.match.params.orgId}/clients`,
                    breadcrumbName: this.props.selectedOrg ? `${this.props.selectedOrg.name}'s Clients` : 'Loading...',
                },
                {
                    path: `/${this.props.match.params.orgId}/clients/${this.props.match.params.clientId}`,
                    breadcrumbName: this.props.client ? this.props.client.displayName : 'Loading...',
                },
            ],
        };
    }

    onBack = () => this.props.history.push(`/${this.props.match.params.orgId}/clients`);
    //#endregion header

    render() {
        if (this.props.hasLoadingError && this.props.loadingError!.code === 73) {
            return (
                <Result
                    status="404"
                    title="404"
                    subTitle="We could not find this client. 🤔"
                    extra={<Button type="primary" onClick={this.onBack}>Back to Clients</Button>}
                />
            );
        }

        const { client } = this.props;

        const headerProps: PageContainerProps = {
            title: client ? client.displayName : 'Loading...',
            tags: this.headerTags,
            content: this.clientHeaderInfo,
            extra: this.headerExtra,
            onBack: this.onBack,
            breadcrumb: this.breadcrumbProps,
            className: 'client-view',
            loading: this.state.isLoading,
        };

        return (
            <PageContainer {...headerProps}>
                {this.hasPendingUpdate}
                {this.entitiesAndRelated}
                {this.filesAndCommunication}
                {this.timelineCard}
                {this.mailModal}
                {this.pendingUpdateModal}
            </PageContainer>
        );
    }
}

export const Client = connect(mapStateToProps)(withRouter(ClientBase));
